<template>
  <div>
    <page-main
      back
      :title="''"
      class="container"
    >
      <template slot="top">
        <Steps
          :value="1"
          :list="[
            `1.新建${projectText}`,
            '2.发布委托',
            '3.确认并提交审核'
          ]"
        />
      </template>
      <el-row type="flex" class="container-wrapper">
        <div class="scene-slide-left">
          <add-question-card
            :show-type="showType"
            :project-type="projectType"
            @onAddExam="onAdd"
          />
          <div class="mgn-b10">
            <div class="font-s18 font-weight mgn-b20">其他功能</div>
            <div class="text-primary-link mgn-t20" @click="onShowMaterialExam">
              从素材库添加
            </div>
            <div
              class="text-primary-link mgn-t20"
              @click="onSetJump()"
            >
              {{ hasSetJump ? '编辑跳题逻辑' : '设置跳题逻辑' }}
            </div>
          </div>
          <el-row class="down-link">
            你可以
            <span
              class="text-primary-link"
              @click="onDownTemplate"
            >点击这里下载</span>
            模版，填写完成后，
            <upload-sheet-to-json
              class="link-wrapper"
              start="A2:"
              @onUpload="onUploadedExam"
            >
              <span class="text-primary-link">点击这里上传</span> 批量导入问卷
            </upload-sheet-to-json>
          </el-row>
        </div>
        <div class="scene-layout">
          <el-form label-width="135px" label-suffix="：" class="form-item">
            <el-form-item required :label="`${projectText}标题`">
              <el-input
                v-model="title"
                class="scene-form-input"
                :placeholder="
                  `请输入${projectText}标题，标题不能包含产品名称和组织名称`
                "
                :maxlength="titleLimit"
                show-word-limit
              />
            </el-form-item>
            <el-form-item required :label="`${projectText}简介`">
              <el-input
                v-model="intro"
                type="textarea"
                class="scene-form-input"
                :placeholder="
                  `请输入${projectText}简介，简介不能包含产品名称和组织名称`
                "
                rows="8"
                maxlength="400"
                show-word-limit
              />
            </el-form-item>
            <el-form-item
              required
              label="标签选择"
            >
              <div class="scene-form-content">
                <ProductLabelKnowledge
                  v-if="selectedTagList.length"
                  :selected-tag-list="selectedTagList"
                  :disabled-ids="disabledIds"
                  @change="onTagChange"
                />
                <div>
                  <el-button plain type="info" @click="onOpenTagModal">
                    添加标签<svg-icon name="el-icon-plus" />
                  </el-button>
                </div>
              </div>
            </el-form-item>
            <StrategyProjectFormItem :form.sync="form" />
            <el-form-item label="封面底图">
              <el-radio-group v-model="radioType">
                <el-radio :label="0">默认</el-radio>
                <el-radio :label="1">自定义</el-radio>
              </el-radio-group>
              <div v-if="radioType === 0" class="cover-default-box">
                <template v-for="item of coverImgList">
                  <div
                    :key="item.imageName"
                    class="cover-default-img"
                    :class="{'select-default': defaultSelectImg.imageName === item.imageName}"
                    @click="onSelectImg(item)"
                  >
                    <img class="cover-img" :src="item.middle" alt="">
                    <img v-if="defaultSelectImg.imageName === item.imageName" :src="require('@/assets/academic/select_btn.png')" class="select-btn">
                  </div>
                </template>
              </div>
              <VueCropper
                v-else
                action=""
                width="102px"
                height="195px"
                :value="coverUrlObj"
                @uploadImg="row => uploadImg('coverUrl', row)"
              >
                <div>上传封面</div>
              </VueCropper>
            </el-form-item>
            <el-form-item required label="添加题目" />
          </el-form>
          <ExamList
            ref="scrollNode"
            v-loading.fullscreen.lock="isLoading"
            :exam-list="examList"
            :scene-id="sceneId"
            :is-need="true"
            :show-jump="projectType === 1"
            :is-attribute="isAttribute"
            :current-index="currentIndex"
            :suitable-type="type"
            :default-value-type="undefined"
            style="margin-top: -15px;"
            @onTiggerExam="onTiggerExam"
            @onTitleTiggerExam="onTitleTiggerExam"
            @initTemp="initTemp"
            @onSetJump="onSetJump"
            @onSave="onSave"
          />
        </div>
        <!-- 右边 相似题目提醒 -->
        <SimilarExamList
          :similar-exam-list="similarExamList"
          :show-index="showIndex"
          :current-exam-item="currentExamItem"
          @onReplaceExam="onReplaceExam"
          @onSave="onSave"
          @onChangeNextExam="changeNextExam"
        />
      </el-row>
      <div
        v-show="isShowToast"
        class="toast-box"
        :class="{ errorBg: isErrorBg }"
      >
        {{ toastMessage }}
      </div>
    </page-main>
    <fixed-action-bar>
      <el-button
        style="min-width: 90px;"
        plain
        type="primary"
        @click="onOpenOperationConfirmPop"
      >
        取消
      </el-button>
      <el-button
        style="min-width: 90px;"
        plain
        type="primary"
        @click="() => {
          showPreview = true
        }"
      >
        预览
      </el-button>
      <el-button
        style="min-width: 90px;"
        type="primary"
        plain
        :loading="isSubmit"
        @click="onSubmit"
      >
        保存
      </el-button>
      <el-button
        style="min-width: 90px;"
        type="primary"
        :loading="isSubmit"
        @click="onSubmit(true)"
      >
        发布并进行委托
      </el-button>
    </fixed-action-bar>
    <add-article-modal
      :visible="isShowArticleModal"
      :select-list="pdfList"
      :knowledge-type="1"
      @onSubmit="onSubmitArticle"
      @onClose="onClosePop"
    />
    <ProductLabel
      :visible.sync="showTagModal"
      :disabled-ids="disabledIds"
      :form.sync="form"
      :selected-tag-list="selectedTagList"
      :options="{
        SPREAD_DEPARTMENT_GROUP: {
          isShowTab: false
        },
        CUSTOMIZE_TAG_GROUP: {
          isShowTab: false
        }
      }"
      @onSubmit="onSubmitTag"
    />
    <OperationConfirmPop
      :visible="isShowOperationConfirmPop"
      message="当前页面未编辑完成，您确定要离开当前编辑页面吗？"
      title="操作确认"
      cancel-font="继续编辑"
      confirm-font="确定离开"
      @onClose="onClosePop"
      @onSubmit="onSubmitPop"
    />
    <OperationConfirmPop
      :visible="showExamJumpValidModal"
      :message="actionMessage"
      title="操作确认"
      cancel-font="取消"
      confirm-font="确定删除"
      @onClose="onClosePop"
      @onSubmit="onDelSceneExamJumpLogic"
    />
    <scene-exam-jump-modal
      :visible="showJumpOptModal"
      :exam-list="examList"
      :add-exam-jump-item="actionExamItem"
      :scene-id="sceneId"
      @onClose="onClosePop(1)"
      @onSubmit="onSubmitSetJump"
    />
    <VideoItemUpload
      :visible="isShowExamVideoModal"
      :video-title="currentExamResourceActionName"
      :video-list="currentExamVideoList"
      @onSubmit="onSuccessExamVideo"
      @onClose="onCloseExamReSourceUploadModal"
    />
    <ImageItemUpload
      :visible="isShowExamImageModal"
      :image-list="currentExamImageList"
      :bucket-type="125"
      :file-size-limit="10"
      @onSubmit="onSuccessExamImage"
      @onClose="onCloseExamReSourceUploadModal"
    />
    <VideoPlayModal
      :visible="showVideoPreviewModal"
      :title="currentExamResourceActionName"
      :video-url="videoPreviewUrl"
      @onClose="onCloseExamReSourceUploadModal"
    />
    <SelectDoctorModal
      v-model="selectDoctorVisible"
      :selected="selectedDoctorList"
      :max="1"
      @onSubmit="onDoctorListSubmit"
    />
    <AddExamModal
      :visible="showSelectMaterialExamModal"
      :select-list="selectExamList"
      :exam-type="1"
      show-all-exam="showAllExam"
      category-type="questionnaireExam"
      @onSubmit="onSubmitExam"
      @onClose="onClosePop"
    />
    <PreviewPureSurvey
      :visible.sync="showPreview"
      :form-obj="{
        title,
        intro,
        examList,
        coverUrl: radioType === 0 ? defaultSelectImg : coverUrlObj
      }"
    />
  </div>
</template>
<script>
// import ImgUpload from './components/imgUpload.vue'
import AddArticleModal from '@/bizComponents/AddArticleModal'
import OperationConfirmPop from '@/bizComponents/OperationConfirmPop'
import SelectDoctorModal from '@/bizComponents/SelectDoctorModal'
import SimilarExamList from '@/components/SimilarExamList'
import VideoItemUpload from '@/bizComponents/VideoItemUpload'
import ImageItemUpload from '@/bizComponents/ImageItemUpload'
import SceneExamJumpModal from './child/SceneExamJumpModal'
import UploadSheetToJson from '@/bizComponents/UploadSheetToJson'
import VideoPlayModal from '@/bizComponents/VideoPlayModal'
import { mapActions, mapGetters } from 'vuex'
import { setGuid } from '@/utils/util'
import { checkExcel } from '@/utils/exam'
import { saveAs } from 'file-saver'
import { getCategoryList } from '@/services/materialService'
import ExamList from '@/components/ExamList'
import AddExamModal from '@/bizComponents/AddExamModal'
import AddQuestionCard from '@/components/AddQuestionCard'
import ProductLabel from '@/bizComponents/ProductLabel'
import ProductLabelKnowledge from '@/bizComponents/ProductLabelKnowledge'
import StrategyProjectFormItem from './components/strategy-project-form-item.vue'
import PreviewPureSurvey from './components/PreviewPureSurvey.vue'
import VueCropper from '@/baseComponents/VueCropper.vue'

export default {
  name: 'CreateSurvey',
  filters: {
    similarAnswerFilter(answers) {
      const output = []
      const arr = [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T'
      ]
      answers.map((v, i) => {
        if (v.isCorrect) {
          output.push(arr[i])
        }
      })
      return output.join('、')
    }
  },
  components: {
    PreviewPureSurvey,
    // ImgUpload,
    AddArticleModal,
    ProductLabel,
    ProductLabelKnowledge,
    SelectDoctorModal,
    VideoItemUpload,
    ImageItemUpload,
    UploadSheetToJson,
    VideoPlayModal,
    SimilarExamList,
    SceneExamJumpModal,
    OperationConfirmPop,
    ExamList,
    AddExamModal,
    AddQuestionCard,
    StrategyProjectFormItem,
    VueCropper
  },
  data() {
    return {
      dialogVisible: false,
      showPreview: false,
      radioType: 0,
      coverUrlObj: {},
      defaultSelectImg: {
        middle: 'https://cdnimg1.yaomaitong.cn/ksh/cover/questionnaire_bgc_1.png?x-oss-process=style/nologo_m', // 默认封面
        imageName: 'ksh/cover/questionnaire_bgc_1.png'
      },
      coverImgList: [
        {
          middle: 'https://cdnimg1.yaomaitong.cn/ksh/cover/questionnaire_bgc_1.png?x-oss-process=style/nologo_m', // 默认封面
          imageName: 'ksh/cover/questionnaire_bgc_1.png'
        },
        {
          middle: 'https://cdnimg1.yaomaitong.cn/ksh/cover/questionnaire_bgc_2.png?x-oss-process=style/nologo_m', // 默认封面
          imageName: 'ksh/cover/questionnaire_bgc_2.png'
        }

      ],
      questionnaireStatus: 0,
      sceneId: '',
      type: '',
      userTagType: 6,
      isLoading: false,
      sceneTypeId: 4,
      canEdit: true,
      hasSave: false,
      templateList: [],
      hasSetJump: false,
      toastMessage: '',
      projectType: 1, // 1 questionnaire  2 caseReport
      isErrorBg: false,
      currentExamImageList: [],
      currentExamResourceActionName: '',
      currentExamVideoList: [],
      isShowExamImageModal: false,
      selectDoctorVisible: false,
      isShowExamVideoModal: false,
      selectedDoctorList: [],
      currentExamResourceActionId: '',
      showVideoPreviewModal: false,
      videoPreviewUrl: '',
      actionMessage: '', // 操作弹窗消息提示
      isShowToast: false,
      actionExamId: '',
      showExamJumpValidModal: false,
      pdfId: '',
      pdfTitle: '',
      actionFuncBack: null, //  操作function 回调
      isShowOperationConfirmPop: false,
      showSelectMaterialExamModal: false,
      pdfList: [],
      actionExamItem: {},
      similarExamList: [],
      selectedTagList: [], // 标签列表
      disabledIds: [],
      showTagModal: false, // 添加modal
      loading: false,
      isAttribute: false,
      isShowArticleModal: false,
      showJumpOptModal: false,
      hasCreate: false,
      isSubmit: false,
      title: '',
      intro: '',
      isPublish: false,
      msg: '',
      isInfo: '',
      sceneType: 'questionnaire',
      examType: 'single', // 1.single 2.multiple 3.blank_fill 4.text_fill 5.end
      examList: [], // 1.single 2.multiple 3.blank_fill 4.text_fill 5.end
      isCopy: false, // 是否是复制活动进来
      disabledBack: false,
      optionsList: [],
      replaceExamId: '',
      selectExamList: [],
      form: {
        answerDuration: null,
        projectId: '',
        projectName: '',
        strategyId: '',
        strategyName: '',
        coverUrl: 'ksh/cover/questionnaire_bgc_1.png'
      },
      productList: [],
      showIndex: 0,
      currentExamItem: {}
    }
  },
  computed: {
    ...mapGetters(['tagGroupList']),
    showType() {
      const obj = {
        single: true,
        multiple: true,
        blank_fill: true,
        text_fill: true,
        sort: true,
        scale: true,
        rich_text: this.projectType !== 1,
        page_end: this.projectType !== 1
      }
      return obj
    },
    projectText() {
      if (this.projectType === 1) {
        return '调研'
      }
      return ''
    },
    titleLimit() {
      if (this.projectType === 1) {
        return 100
      }
      return ''
    },
    totalPageIndex() {
      let totalPageIndex = 0
      let isLastPageIndex = false
      this.examList.map((v, i, arr) => {
        if (v.answerType === 'page_end') {
          totalPageIndex++
          isLastPageIndex = i === arr.length - 1
        }
      })
      // 假设最后是一个分页器认为是最后一页
      return isLastPageIndex ? totalPageIndex : totalPageIndex + 1
    },
    currentPageIndex() {
      const currentPageList = [...this.examList].filter(
        v => v.answerType === 'page_end'
      )
      let currentIndex = 0
      return currentId => {
        currentPageList.map((c, i) => {
          if (currentId === c.id) {
            currentIndex = i + 1
          }
        })
        return currentIndex
      }
    },
    authorId() {
      return (this.selectedDoctorList[0] || {}).doctorId
    },
    currentIndex() {
      return currentExamId => {
        let current = 0
        let output = 0
        this.examList.map(v => {
          if (v.answerType !== 'page_end' && !v.richTextContent) {
            current++
            if (currentExamId === v.id) {
              output = current
            }
          }
        })
        return output
      }
    }
  },
  mounted() {
    this.sceneId = this.$route.params.id
    this.isCopy = this.$route.query.copy === 'copy'
    this.type = this.$route.query.type
    this.onGetProductList()
    this.getTagGroup()
    if (!this.sceneId) {
      return
    }
    this.init()
    this.getOptionsList()
  },
  methods: {
    ...mapActions([
      'getQuestionnaireDetail',
      'onEditQuestionnaire',
      'getSceneDetailTag',
      'getSimilarExamList',
      'getSceneExamJumpRuleList',
      'onValidExamJumpLogic',
      'onDelSceneExamJumpRule',
      'getVideoUrl',
      'getSceneTemplateList',
      'getTagGroup'
    ]),
    onSelectImg(item) {
      this.defaultSelectImg = item
    },
    // 上传图片
    uploadImg(type, row) {
      console.log(type, row, '=======')
      this.form[type] = row?.imageName
      this.coverUrlObj = row || {}
      this.dialogVisible = true
    },
    onCheckReq() {
      for (const item of this.selectedTagList) {
        console.log(this.tagGroupList)
        console.log(item.groupId)
        if (this.tagGroupList[0]?.id === item.groupId) {
          return false
        }
      }
      return true
    },
    onOpenOperationConfirmPop() {
      if (!this.isSaveConfirm) {
        this.isShowOperationConfirmPop = true
      } else {
        this.goBack()
      }
    },
    onOpenDoctorModal() {
      this.selectDoctorVisible = true
    },
    onDoctorListSubmit(list) {
      this.selectedDoctorList = list
      this.selectDoctorVisible = false
    },
    onShowExamResourceModal(resourceType, item) {
      // 题目资源弹窗
      this.currentExamResourceActionId = item.id
      switch (resourceType) {
        case 1:
          this.isShowExamImageModal = true
          this.currentExamImageList = [...(item.examResourceBOList || [])]
            .filter(v => v.resourceType === 1)
            .map(k => {
              return {
                ...k.resource,
                id: k.id || ''
              }
            })
          break
        case 2:
          this.currentExamResourceActionName = item.title
          this.isShowExamVideoModal = true
          this.currentExamVideoList = [...(item.examResourceBOList || [])]
            .filter(v => v.resourceType === 2)
            .map(k => k.resource)
          break
        default:
          break
      }
    },
    onShowVideoPreviewModal(url, videoId) {
      if (url) {
        this.videoPreviewUrl = url
        this.showVideoPreviewModal = true
      } else if (videoId) {
        this.getVideoUrl(videoId).then(
          res => {
            const resData = res.playUrl || []
            this.videoPreviewUrl = resData.filter(
              v => v.indexOf('.mp4') > -1
            )[0]
            if (!this.videoPreviewUrl)
              return this.$message.error('视频资源加载中，请稍后尝试')
            this.showVideoPreviewModal = true
          },
          rea => {
            this.$message.error(rea)
          }
        )
      }
    },
    onCloseExamReSourceUploadModal() {
      this.isShowExamImageModal = false
      this.isShowExamVideoModal = false
      this.currentExamImageList = []
      this.currentExamVideoList = []
      this.currentExamResourceActionId = ''
      this.currentExamResourceActionName = ''
      this.videoPreviewUrl = ''
      this.showVideoPreviewModal = false
    },
    onSuccessExamImage(imageList) {
      this.currentExamImageList = [...imageList]
      this.examList = [...this.examList].map(v => {
        if (this.currentExamResourceActionId === v.id) {
          const examResourceVideoList = [
            ...(v.examResourceBOList || [])
          ].filter(k => k.resourceType === 2)
          const examResourceImageList = [...this.currentExamImageList].map(
            (d, i) => {
              return {
                id: d.id || '',
                resourceType: 1,
                resource: d,
                resourceIndex: i
              }
            }
          )
          v = {
            ...v,
            examResourceBOList: [
              ...examResourceVideoList,
              ...examResourceImageList
            ]
          }
        }
        return v
      })
      this.currentExamResourceActionName = ''
      this.currentExamResourceActionId = ''
      this.currentExamImageList = []
      this.isShowExamImageModal = false
    },
    onSuccessExamVideo(videoList) {
      this.currentExamVideoList = [...videoList]
      this.examList = [...this.examList].map(v => {
        if (this.currentExamResourceActionId === v.id) {
          const examResourceImageList = [
            ...(v.examResourceBOList || [])
          ].filter(k => k.resourceType === 1)
          const examResourceVideoList = [...this.currentExamVideoList].map(
            d => {
              return {
                id: '',
                resourceType: 2,
                resource: d,
                resourceIndex: 0
              }
            }
          )
          v = {
            ...v,
            examResourceBOList: [
              ...examResourceVideoList,
              ...examResourceImageList
            ]
          }
        }
        return v
      })
      this.isShowExamVideoModal = false
      this.currentExamResourceActionId = ''
      this.currentExamResourceActionName = ''
      this.currentExamImageList = []
    },
    onDownTemplate() {
      saveAs(
        'https://cdnfile.yaomaitong.cn/excel/examModelUploadV2.xlsx',
        '活动题目导入模版.xlsx'
      )
    },
    onChangeProjectType() {
      // 切换活动类型
      this.examList = []
    },
    onSelectTemplate(current) {
      this.examList = this.$refs.scrollNode.newExamList
      this.replaceExamId = this.$refs.scrollNode.replaceExamId
      this.examList = [...this.examList].map(v => {
        if (v.id === this.replaceExamId) {
          v.richTextContent = current.content
        }
        return v
      })
    },
    onUploadedExam(json) {
      this.examList = checkExcel(json, this.$refs.scrollNode.newExamList, this.$refs.scrollNode.optionsList, this.productList)
    },
    onSubmitExam(arr) {
      this.examList = this.$refs.scrollNode.newExamList
      const examList = arr.map(v => {
        const answerList = [...(v.answers || [])].map(k => {
          return {
            title: k.answer,
            answerId: k.examAnswerId,
            orderIndex: k.orderIndex,
            isCorrect: k.isCorrect
          }
        })
        const sourceList = [...(v.examResourceBOList || [])].map(d => {
          if (d.resourceType === 1) {
            return {
              id: d.id,
              resource: d.imageVo,
              resourceIndex: d.resourceIndex,
              resourceType: d.resourceType
            }
          } else {
            const playUrl =
              [...(d.playUrls || [])].filter(c => c.indexOf('.mp4') > -1)[0] ||
              ''
            return {
              id: d.id,
              resource: { videoId: d.resource, url: playUrl },
              resourceIndex: d.resourceIndex,
              resourceType: d.resourceType
            }
          }
        })
        return {
          ...v,
          answerType: v.examTypeEnum,
          id: setGuid(),
          examId: v.examId,
          save: true,
          title: v.title,
          examRichTextContent: v.examRichTextContent,
          scaleQuestionList: v.scaleQuestionList,
          leastAnswerCount: v.leastAnswerCount,
          knowledgePointId: v.knowledgePointId,
          knowledgePointName: v.knowledgePointName,
          isRealCanDel: v.isRealCanDel,
          answers: answerList,
          examResourceBOList: sourceList
        }
      })
      this.hasSave = false
      this.showSelectMaterialExamModal = false
      // 先添加
      examList.forEach(item => {
        const flag = this.examList.some(
          examListItem => examListItem.examId === item.examId
        )
        if (!flag) this.examList.push(item)
      })
      // 后删除
      this.examList = this.examList.filter(examListItem => {
        if (!examListItem.examId) {
          return true
        } else {
          return examList.some(item => item.examId === examListItem.examId)
        }
      })
      this.$message.success('从素材库添加成功')
    },
    goBack() {
      this.$router.replace('/academic/list')
    },
    onLookJumpList() {
      this.$router.push(`/academic/questionnaire/jump/tree/${this.sceneId}`)
    },
    onClosePop(v) {
      this.showSelectMaterialExamModal = false
      this.isShowArticleModal = false
      this.showTagModal = false
      this.isShowOperationConfirmPop = false
      this.showExamJumpValidModal = false
      this.showJumpOptModal = false
      if (v) {
        this.init()
      }
    },
    onSubmitPop() {
      this.$router.push('/academic/list')
    },
    onSubmitSetJump(setJumpList = []) {
      this.hasSetJump = setJumpList.length > 0
      this.examList = [...this.examList].map(v => {
        v = { ...v, hasSet: setJumpList.indexOf(v.examId) > -1 }
        return v
      })
    },
    onShowMaterialExam() {
      if (this.isAttribute)
        return this.$message.error('该活动已分配给医生，题目不支持修改')
      this.selectExamList = this.$refs.scrollNode.newExamList.filter(
        v => v.examId
      )
      this.showSelectMaterialExamModal = true
    },
    onSubmitTag(selectedList) {
      this.showTagModal = false
      this.selectedTagList = [...selectedList]
    },
    onTagChange(tagList) {
      this.selectedTagList = tagList
    },
    onOpenTagModal() {
      this.showTagModal = true
    },
    onSubmitArticle(arr) {
      if (this.isAttribute)
        return this.$message.error('该活动已分配给医生，文献不支持修改')
      this.pdfList = arr
    },
    onDelArticle(id) {
      if (this.isAttribute)
        return this.$message.error('该活动已分配给医生，文献不支持删除')
      this.pdfList = [...this.pdfList].filter(v => v.knowledgeId != id)
    },
    onOpenArticlePop() {
      if (this.isAttribute)
        return this.$message.error('该活动已分配给医生，文献不支持修改')
      this.isShowArticleModal = true
    },
    answerTypeFunc(type) {
      let output = ''
      switch (type) {
        case 'single':
          output = 0
          return 0
        case 'multiple':
          output = 1
          return 1
        case 'blank_fill':
          output = 2
          return 2
        case 'text_fill':
          output = 3
          return 3
        case 'sort':
          output = 5
          return 5
        case 'scale':
          output = 6
          return 6
      }
      return output
    },
    changeNextExam() {
      if (this.showIndex < this.similarExamList.length - 1) {
        this.showIndex++
      } else {
        this.showIndex = 0
      }
    },
    onReplaceExam(currentItem) {
      this.examList = this.$refs.scrollNode.newExamList
      // 如果this.examList里面有相同的题目，提示不能替换
      const flag = this.examList.some(
        examListItem => examListItem.examId === currentItem.examId
      )
      if (flag) {
        this.$message.error('该题目已存在')
        return
      }
      let replaceExam = {}
      if (
        currentItem.examTypeEnum === 'multiple' ||
        currentItem.examTypeEnum === 'single' ||
        currentItem.examTypeEnum === 'sort'
      ) {
        const child = currentItem.answers.map(v => {
          return {
            title: v.answer,
            answerId: v.examAnswerId,
            orderIndex: v.orderIndex,
            isCorrect: v.isCorrect
          }
        })
        replaceExam = {
          title: currentItem.title,
          examId: currentItem.examId,
          examRichTextContent: currentItem.examRichTextContent,
          leastAnswerCount: currentItem.leastAnswerCount,
          knowledgePointId: currentItem.knowledgePointId,
          knowledgePointName: currentItem.knowledgePointName,
          answerType: currentItem.examTypeEnum,
          isRealCanDel: currentItem.isRealCanDel,
          isSetCorrectAnswer: currentItem.isSetCorrectAnswer,
          memberTagInfo: currentItem.tagInfos,
          id: setGuid(),
          answers: child
        }
      } else if (currentItem.examTypeEnum === 'scale') {
        const child = currentItem.answers.map(v => {
          return {
            title: v.answer,
            answerId: v.examAnswerId
          }
        })
        replaceExam = {
          title: currentItem.title,
          examId: currentItem.examId,
          examRichTextContent: currentItem.examRichTextContent,
          knowledgePointId: currentItem.knowledgePointId,
          knowledgePointName: currentItem.knowledgePointName,
          scaleQuestionList: currentItem.scaleQuestionList,
          answerType: currentItem.examTypeEnum,
          isRealCanDel: currentItem.isRealCanDel,
          id: setGuid(),
          answers: child
        }
      } else {
        replaceExam = {
          id: setGuid(),
          title: currentItem.title,
          examId: currentItem.examId,
          examRichTextContent: currentItem.examRichTextContent,
          knowledgePointId: currentItem.knowledgePointId,
          knowledgePointName: currentItem.knowledgePointName,
          answerType: currentItem.examTypeEnum,
          isRealCanDel: currentItem.isRealCanDel,
          answers: null
        }
      }
      const sourceList = [...(currentItem.examResourceBOList || [])].map(d => {
        if (d.resourceType === 1) {
          return {
            id: d.id,
            resource: d.imageVo,
            resourceIndex: d.resourceIndex,
            resourceType: d.resourceType
          }
        } else {
          const playUrl =
            [...(d.playUrls || [])].filter(c => c.indexOf('.mp4') > -1)[0] || ''
          return {
            id: d.id,
            resource: { videoId: d.resource, url: playUrl },
            resourceIndex: d.resourceIndex,
            resourceType: d.resourceType
          }
        }
      })
      this.examList = [...this.examList].map(v => {
        if (v.id === this.replaceExamId) {
          v = {
            ...v,
            ...replaceExam,
            save: !v.isInset,
            examResourceBOList: sourceList,
            answers: replaceExam.answers,
            tagInfos: currentItem.tagInfos
          }
        }
        return v
      })
      this.similarExamList = []
      this.$message.success('替换成功')
    },
    onTitleTiggerExam(obj) {
      this.replaceExamId = obj.id
      this.onReplaceExam(obj)
    },
    onTiggerExam(obj) {
      this.isLoading = true
      this.$axios.post(this.$API.getSimilarList, obj).then(
        res => {
          this.isLoading = false
          if (res) {
            this.similarExamList = res.data || []
            this.replaceExamId = obj.id
            this.currentExamItem = obj
            if (!this.similarExamList.length) {
              this.onSave(this.currentExamItem)
            }
          }
        },
        err => {
          this.isLoading = false
          this.$message.error(err.message)
        }
      )
    },
    onSave(obj) {
      this.examList = this.$refs.scrollNode.newExamList
      this.examType = obj.answerType
      this.examList = [...this.examList].map(item => {
        if (obj.id === item.id) {
          item = { ...item, ...obj, save: obj.save }
        }
        return item
      })
      this.similarExamList = []
    },
    onDel(id = '') {
      if (this.isAttribute)
        return this.$message.error('该活动已分配给医生，题目不支持删除')
      this.examType = ''
      this.$message.success('题目删除成功')
      this.examList = [...this.examList].filter(v => v.id !== id)
    },
    onMoveUp(index, isTop) {
      if (this.isAttribute)
        return this.$message.error('该活动已分配给医生，题目不支持修改')
      if (isTop) return
      const newlist = [...this.examList]
      newlist[index] = newlist.splice(index - 1, 1, newlist[index])[0]
      this.examList = newlist
    },
    onMoveDown(index, isEnd) {
      if (this.isAttribute)
        return this.$message.error('该活动已分配给医生，题目不支持修改')
      if (isEnd) return
      const newlist = [...this.examList]
      newlist[index] = newlist.splice(index + 1, 1, newlist[index])[0]
      this.examList = newlist
    },
    compare(props) {
      return (obj1, obj2) => {
        const val1 = obj1[props]
        const val2 = obj2[props]
        return val1 - val2
      }
    },
    onValidExamJump(call, examId = '') {
      if (this.isAttribute)
        return this.$message.error('该活动已分配给医生，题目不支持修改')
      if (!examId) {
        // 没有examId 代表着不需要查询当前题目的跳题逻辑 直接整体删除跳题逻辑
        this.showExamJumpValidModal = true
        this.actionMessage =
          '当前活动已设置跳题逻辑，若改变题目位置，将删除该活动的跳题逻辑'
        this.actionExamId = ''
        this.actionFuncBack = call
        return
      }
      this.onValidExamJumpLogic({ examId, sceneId: this.sceneId }).then(res => {
        this.showExamJumpValidModal = res
        this.actionMessage =
          '当前题目已设置跳题逻辑，若进行编辑，将删除关联的跳题逻辑'
        this.actionExamId = examId
        this.actionFuncBack = call
        if (!res) {
          if (typeof call === 'function') {
            call(true)
          }
          this.examList = [...this.examList].map(v => {
            if (examId === v.examId) {
              v = { ...v, hasSet: false }
            }
            return v
          })
        }
      })
    },
    onDelSceneExamJumpLogic() {
      this.onDelSceneExamJumpRule({
        examId: this.actionExamId,
        sceneId: this.sceneId
      }).then(
        () => {
          this.examList = [...this.examList].map(v => {
            if (this.actionExamId === v.id || this.actionExamId === '') {
              v = { ...v, hasSet: false }
            }
            return v
          })
          if (!this.actionExamId) {
            this.hasSetJump = false
            this.$message.success('该活动跳题逻辑已删除')
          }
          this.showExamJumpValidModal = false
          if (typeof this.actionFuncBack === 'function') {
            this.actionFuncBack()
          }
          this.actionFuncBack = null
          // this.$message.success('当前题目相关跳题逻辑已删除')
          this.actionExamId = ''
        },
        rea => {
          this.$message.error(rea)
        }
      )
    },
    bySort(array) {
      return array.sort(this.compare('examIndex'))
    },
    onSetJump(currentItem = {}) {
      this.examList = this.$refs.scrollNode.newExamList
      if (this.isAttribute)
        return this.$message.error(
          `该${this.projectText}已分配给医生，跳题逻辑不支持修改`
        )
      if (this.examList.length < 3)
        return this.$message.error('设置跳题逻辑要求至少三道题目')
      this.actionExamItem = currentItem
      this.onSubmit(false, true)
    },
    onAdd(type, index) {
      this.$refs.scrollNode.onAddExam(type, index)
      this.examType = this.$refs.scrollNode.examType
      this.hasSave = false
    },
    initScrollTop(currentY = 0, targetY = 0) {
      const needScrollTop = targetY - currentY
      let _currentY = currentY
      this.timer = setTimeout(() => {
        const dist = Math.ceil(needScrollTop / 20)
        _currentY += dist
        this.$refs.scrollNode.scrollTop = currentY
        // window.scroll(_currentY, currentY)
        if (needScrollTop > 10 || needScrollTop < -10) {
          this.initScrollTop(_currentY, targetY)
        } else {
          // window.scroll(_currentY, targetY)
          this.$refs.scrollNode.scrollTop = currentY
          this.timer && clearTimeout(this.timer)
        }
      }, 1)
    },
    initData(res) {
      this.questionnaireStatus = res.questionnaireStatus
      let pdfList = []
      if (res.authorId) {
        this.selectedDoctorList = [
          {
            doctorId: res.authorId,
            doctorName: res.authorName,
            titleName: res.authorTitle
          }
        ]
      }
      if (res.pdfUrl) {
        pdfList = [
          {
            title: res.pdfTitle,
            pdfUrl: res.pdfUrl,
            knowledgeId: res.knowledgeId
          }
        ]
      }
      if (res.coverUrl) {
        this.radioType = 1
        this.coverImgList.map(item => {
          if (item.imageName === res.coverUrl.imageName) {
            this.radioType = 0
            this.defaultSelectImg = item
          }
        })
        if (this.radioType === 1) {
          this.coverUrlObj = res.coverUrl
        }
      }
      this.pdfList = pdfList
      this.intro = res.intro || ''
      this.title = res.title
      this.form.answerDuration = res.answerDuration
      let currentPageIndex = 1
      // 当前分页
      const newList = []
      res.questionnaireExamVoList.map(item => {
        const sourceList = [...(item.examResourceBOList || [])].map(v => {
          if (v.resourceType === 1) {
            return {
              id: v.id,
              resource: v.imageVo,
              resourceIndex: v.resourceIndex,
              resourceType: v.resourceType
            }
          } else {
            const playUrl =
              [...(v.playUrls || [])].filter(c => c.indexOf('.mp4') > -1)[0] ||
              ''
            return {
              id: v.id,
              resource: { videoId: v.resource, url: playUrl },
              resourceIndex: v.resourceIndex,
              resourceType: v.resourceType
            }
          }
        })
        const answerList = [...(item.answers || [])].map(k => {
          return {
            title: k.answer,
            answerId: k.examAnswerId,
            orderIndex: k.orderIndex,
            isCorrect: k.isCorrect
          }
        })
        item = {
          ...item,
          save: true,
          answers: answerList,
          id: setGuid(),
          examResourceBOList: sourceList,
          answerType: item.richTextContent ? 'rich_text' : item.examTypeEnum
        }
        if (item.pageIndex - currentPageIndex === 1) {
          // 分页器处理
          newList.push({ answerType: 'page_end', id: setGuid() })
          currentPageIndex++
        }
        newList.push(item)
        return item
      })
      if (currentPageIndex > 1) {
        // 补齐最后一个分页显示
        newList.push({ answerType: 'page_end', id: setGuid() })
      }
      const list = this.bySort(newList)
      this.examList = list
    },
    init() {
      this.initTag()
      this.getQuestionnaireDetail(this.sceneId).then(
        res => {
          this.hasSave = true
          this.loading = false
          if (!this.isCopy) {
            this.hasSetJump = res.hasSet
            this.isAttribute = res.isAttribute
            this.canEdit = res.canEdit
            this.disabledBack = !res.canEdit
          } else {
            this.sceneId = ''
          }
          this.form.projectId = res.projectId
          this.form.projectName = res.projectName
          this.form.strategyId = res.strategyId
          this.form.strategyName = res.strategyName
          this.form.strategyDisabled = !!this.form.strategyId

          this.initData(res)
        },
        rea => {
          this.loading = false
          this.$message.error(rea)
        }
      )
    },
    onShowWrapperMsg(msg, isError = true) {
      this.isShowToast = true
      this.toastMessage = msg
      this.isErrorBg = isError
      setTimeout(() => {
        this.toastMessage = ''
        this.isShowToast = false
      }, 3000)
    },
    validSubmit() {
      if (!this.title) {
        this.onShowWrapperMsg(`请填写${this.projectText}标题`)
        return false
      }
      if (!this.intro) {
        this.onShowWrapperMsg(`请填写${this.projectText}简介`)
        return false
      }
      if (this.onCheckReq()) {
        this.onShowWrapperMsg('请选择产品标签')
        return false
      }

      let errorExam = ''
      let hasExam = 0
      this.examList.map(v => {
        // 未保存的题目
        if (!v.save && v.answerType !== 'page_end') {
          errorExam = '存在未完成编辑的题目，请检查'
        }
        if (
          v.answerType &&
          v.answerType !== 'page_end' &&
          v.answerType !== 'rich_text'
        ) {
          hasExam++
        }
        if (
          this.totalPageIndex > 1 &&
          this.examList[this.examList.length - 1].answerType !== 'page_end'
        ) {
          this.examList.push({
            answerType: 'page_end',
            id: setGuid(),
            save: false
          })
        }
      })
      if (hasExam < 5) {
        this.onShowWrapperMsg('问卷题目数量最低5道题，题目数量不足')
        return false
      }
      if (errorExam) {
        this.onShowWrapperMsg(errorExam)
        return false
      }
      return true
    },
    initTemp() {
      if (!this.templateList.length) {
        this.getSceneTemplateList({
          sceneId: this.sceneId,
          sceneType: this.sceneTypeId
        }).then(res => {
          this.templateList = res
        })
      }
    },
    onNext(req) {
      this.$router.replace({ name: 'CreatePureSurvey', query: {questionnaireId: req.id, sceneFilterId: this.$route.query.sceneFilterId} })
    },
    onSubmit(isNext = false, setJump = false) {
      if (!this.$auth('questionnaireDraftEdit'))
        return this.$message.error('暂无权限')
      this.examList = this.$refs.scrollNode.newExamList
      if (!this.validSubmit()) return
      this.isSubmit = true
      let currentPageIndex = 1
      let currentExamIndex = 0
      let examBos = []
      this.examList.map(v => {
        const sourceList = [...(v.examResourceBOList || [])].map(d => {
          let childput = {}
          if (d.resourceType === 1) {
            childput = { ...d, resource: d.resource.imageName }
          } else if (d.resourceType === 2) {
            childput = { ...d, resource: d.resource.videoId }
          }
          return childput
        })
        if (v.richTextContent) {
          // 富文本类型题目删除 题目类型
          delete v.answerType
        }
        if (v.answerType === 'page_end') {
          currentPageIndex++
        } else {
          currentExamIndex++
          examBos = [
            ...examBos,
            {
              ...v,
              pageIndex: currentPageIndex,
              examResourceBOList: sourceList,
              examIndex: currentExamIndex
            }
          ]
        }
      })
      const memberTagInfo = [...this.selectedTagList].map(v => {
        return {
          userTagType: this.userTagType,
          tagId: v.tagId,
          tagName: v.tagName,
          memberId: this.sceneId,
          groupId: v.groupId,
          knowledgePointIds: v.knowledgePointIds
        }
      })
      // 把examBos里的tagInfos或者memberTagInfo转为memberTagInfo，并给ememberTagInfo添加userTagType
      examBos = examBos.map(v => {
        if (v.tagInfos) {
          v.memberTagInfo = v.tagInfos.map(k => {
            return {
              userTagType: 15,
              tagId: k.tagId,
              tagName: k.tagName,
              memberId: this.sceneId,
              groupId: k.groupId,
              knowledgePointIds: k.knowledgePointIds
            }
          })
          delete v.tagInfos
        }
        if (v.memberTagInfo) {
          v.memberTagInfo = v.memberTagInfo.map(k => {
            return {
              userTagType: 15,
              tagId: k.tagId,
              tagName: k.tagName,
              memberId: this.sceneId,
              groupId: k.groupId,
              knowledgePointIds: k.knowledgePointIds
            }
          })
        }
        // 如果有examAnswerSettingBO，就取examAnswerSettingBO，没有的话, examAnswerSettingB0取examAnswerSettingVO的值
        if (!v.examAnswerSettingBO) {
          v.examAnswerSettingBO = v.examAnswerSettingVO
          delete v.examAnswerSettingVO
        }
        return v
      })
      const req = {
        title: this.title,
        intro: this.intro,
        type: this.projectType,
        isAttribute: this.isAttribute,
        knowledgeId: this.pdfList.length ? this.pdfList[0].knowledgeId : '',
        examBos: examBos,
        authorId: this.authorId,
        memberTagInfo: memberTagInfo,
        ...this.form,
        coverUrl: this.radioType === 0 ? this.defaultSelectImg.imageName : this.coverUrlObj.imageName,
        status: isNext === true ? 1 : this.questionnaireStatus
      }
      if (this.sceneId) {
        req['id'] = this.sceneId
      }
      this.onEditQuestionnaire(req).then(
        res => {
          this.isSubmit = false
          this.loading = false
          this.hasSave = true
          this.hasCreate = true
          this.sceneId = res.id
          this.initData(res)
          if (isNext === true) {
            this.onNext(res)
          } else {
            if (setJump) {
              this.$nextTick(() => {
                this.showJumpOptModal = true
              })
              return
            }
            this.$message.success('保存成功')
            setTimeout(() => {
              this.goBack()
            }, 1000)
          }
        },
        rea => {
          this.isSubmit = false
          this.$message.error(rea)
        }
      )
    },
    initTag() {
      const groupIds = this.tagGroupList.map(v => v.id)
      const req = {
        memberIdList: [this.sceneId],
        userTagType: this.userTagType,
        tagType: 1,
        groupIds: groupIds
      }
      this.getSceneDetailTag(req).then(res => {
        this.disabledIds = res.map(v => v.tagId)
        this.selectedTagList = [...res].map(v => {
          return { ...v, disabled: true }
        })
      })
    },
    async getOptionsList(categoryType = 'questionnaireExam') {
      try {
        const { data } = await getCategoryList({ categoryType })
        this.optionsList = data
      } catch (error) {
        this.optionsList = []
      }
    },
    onGetProductList() {
      this.$axios.get(this.$API.tagList).then(
        res => {
          this.productList = res?.data || []
        },
        ear => {
          this.$message.error(ear.message)
        }
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.container {
  @media screen and (max-width: 768px) {
    min-width: 1200px;
  }
}
.container-wrapper {
  height: calc(100vh - 220px);
  // position: absolute;
  // top: 10px;
  // left: 10px;
  // right: 10px;
  // bottom: 80px;
}
.scene-slide-left ::v-deep {
  width: 16vw;
  background-color: #fff !important;
  padding-top: 20px;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  margin-right: 10px;
  position: relative;
  padding-bottom: 100px;
  overflow: scroll;
  border-right: 1px solid #eee;
  @media screen and (max-width: 768px) {
    width: 200px;
  }
  .el-card__body {
    position: static;
  }
  .tag {
    min-width: 80px;
  }
  .fix-wrapper {
    position: absolute;
    left: 1.5vw;
    padding-right: 15px;
    bottom: 20px;
    flex-wrap: wrap;
    .link-wrapper {
      display: inline;
      // flex-wrap: nowrap;
    }
  }
}
.scene-slide-right {
  width: 16vw;
  background-color: #fff;
  padding-top: 20px;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  margin-left: 10px;
  overflow-y: auto;
  padding-bottom: 100px;
  border-left: 1px solid #eee;
  .tag {
    min-width: 80px;
  }
  @media screen and (max-width: 768px) {
    width: 200px;
  }
}
.warn-tips {
  font-size: 20px;
  margin-right: 4px;
  color: rgba(246, 98, 94, 1);
}
.similar-exam-wrapper {
  padding-bottom: 20px;
}
.scene-layout {
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 110px;
  overflow-y: scroll;
  flex: 1;
}
.form-item {
  padding-left: 2vw;
  padding-right: 2vw;
}
.scene-form-item {
  margin-bottom: 12px;
}
.scene-form-line-item {
  padding: 20px 3vw 25px;
  margin-left: -3vw;
  margin-right: -3vw;
  border-bottom: 1px solid #e9eaeb;
}
.scene-form-label {
  flex-shrink: 0;
  color: #272e40;
  line-height: 50px;
  min-width: 136px;
  padding-right: 10px;
  text-align: right;
}
.scene-exam-wrapper {
  margin-left: -3vw;
  margin-right: -3vw;
  padding-left: 6vw;
  padding-right: 3vw;
  border-bottom: 1px solid #e9eaeb;
  &:hover {
    background: #f3f7fd;
  }
}
.scene-form-content {
  flex: 1;
}
.scene-form-input ::v-deep {
  flex: 1;
  .el-input__inner {
    height: 32px;
    line-height: 32px;
    padding-left: 20px;
  }
  .el-textarea__inner {
    padding-top: 15px;
    padding-left: 20px;
  }
  .el-input__count {
    font-size: 14px;
    color: #c8c9ce;
  }
}
.custom-tag {
  margin-right: 10px;
  margin-bottom: 5px;
}
.cover-exam-wrapper {
  height: 50px;
}
.upload-wrapper {
  width: 160px;
  height: 90px;
  color: #3d61e3;
  font-size: 12px;
  background-color: #e2e3e7;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    border: 1px dashed #3d61e3;
  }
}
.exam-item {
  color: #272e40;
  font-size: 14px;
  padding: 3px;
  .color-info {
    padding-left: 50px;
  }
  .el-icon-delete {
    color: #e1e2e6;
    font-size: 20px;
    cursor: pointer;
    &:hover {
      color: #333;
    }
  }
  &:hover {
    background: #f3f7fd;
  }
}
.toast-box {
  color: #fff;
  background: green;
}
.rich-template-title {
  font-weight: bold;
  font-size: 18px;
  padding-bottom: 20px;
}
.rich-template-wrapper {
  cursor: pointer;
  padding: 20px 8px;
  margin-left: -25px;
  margin-right: -25px;
  margin-bottom: 15px;
  border: 2px solid #eee;
  &:hover {
    border-color: #3d61e3;
  }
}
.errorBg {
  background: #d8384a;
}
.ex-tip-item {
  width: 360px;
  padding: 10px;
}
.exam-tip-wrapper {
  margin-top: 20px;
  height: 50px;
  border: 1px solid #e9eaeb;
  background: #fff;
}
.down-link {
  position: absolute;
  bottom: 10%;
}
.cover-default-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .cover-default-img {
    width: 102px;
    height: 195px;
    border: 1px dashed #e1e1e1;
    margin-right: 10px;
    .cover-img {
      width: 100%;
      height: 100%;
    }
  }
  .select-default {
    position: relative;
    border: 1px dashed #3d61e3;
    .select-btn {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}
</style>
